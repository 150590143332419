<template>
  <div class="default-taxes">
    <buyer-list-brand-modal :brand="subdomain" ref="detailsModal"   @update-promoter="updatePromoter"/>
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner">
        <breadcrumbs :crumbs="breadcrumbs"/>
        <section>
          <div class="row">
            <div class="col full">
              <!-- <table-tote
                v-model="tableData"
                :dateSearch="true"
                dateFrom="From"
                dateTo="To"
                v-on:modal="notesOpen"
                v-on:modalTwo="operBuyerInfo"
              /> -->

              <DataTable
                title="Buyer's List"
                endpoint="/company/buyers/:company"
                :args="{ company: subdomain }"
                :params="{ letterSearch: currentLetter }"
                ref="table"
                :headers="this.tableContent.columns"
                :custom-columns="['details']"
                searchPlaceholder="Name or Email Search"
                :csv-size="5000"
              >
                <template v-slot:[`item.details`]="{ item }">
                  <v-icon @click="details(item)">mdi-information-outline</v-icon>
                </template>
              </DataTable>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>

import DataTable from "@/components/DataTable.vue";
import BuyerListBrandModal from "../../components/modals/BuyerListBrandModal.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  name: "OrderList",
  components: {
    DataTable,
    BuyerListBrandModal,
    Breadcrumbs
  },
  head() {
    return {
      title: "Buyer List",

    };
  },
  data() {
    return {
      buyers: null,
      currentLetter: null,
      breadcrumbs: [
        ["Brand Dashboard", "/brands/my-brand"],
        ["Buyer's List", null]
      ],
      tableContent: {
        title: "Buyer's List",
        columns: [
          {text: "Name", value: "name"},
          {text: "Email", value: "email"},
          {text: "Orders count", value: "order_count"},
          // {text: "Created Date", value: "formatted_created_at"},
          // {text: "Promoter Name", value: "promoter_name"},
          {
            text: "Details",
            value: "details",
            sortable: false
          }
        ],
      },
      subdomain: this.$route.params.subdomain,
    }
  },
  methods: {
    details(item) {
      this.$refs.detailsModal.open(item);
    },
    updatePromoter(promoter, email){
      this.$axios.post('/company/new-promoter/' + this.subdomain, {
        'email': email,
        'newPromoter': promoter
      });
    }
  },
  created() {
    if(this.subdomain == 'nmp'){
      let newColumn = { text: "Registration count", value: "registration_count", sortable: false };

// Insert the new column before the "Details" column
      this.tableContent.columns.splice(3, 0, newColumn);
    }
  }
}
</script>

<style scoped>

</style>