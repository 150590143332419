<template>
  <TTDialog
    title="Buyer Details"
    :sub-title="name + ': ' + email"
    :active="active"
    v-on:close="close"
    :width="650"
  >
    <p>
      <b>
        Line items with (R) next to them are registrations. These are not
        purchases.
      </b>
    </p>
    <v-app>
      <v-card-text>
        <v-row v-for="(order, index) in orders" :key="index">
          <v-col cols="1">{{ index + 1 }}</v-col>
          <v-col cols="4">{{ order.event_name }}</v-col>
          <v-col cols="3">{{ order.promoter_name }}</v-col>
          <v-col cols="2">{{ order.formatted_order_date }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="12" v-if="this.brand == 'nmp'">
            <v-combobox
              label="Update Promoter"
              outlined
              :items="promoters"
              :value="newPromoterValue"
              @input="updatePromoterLocal"
            ></v-combobox>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="saveButton"
            class="save-btn"
            color="var(--primary)"
            large
            @click="updatePromoterEvent"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-app>
  </TTDialog>
</template>

<script>
import TTDialog from "@/components/TTDialog.vue";

export default {
  name: "BuyerListBrandModal",
  components: {
    TTDialog
  },
  props: {
    brand: String
  },
  data() {
    return {
      active: false,
      orders: [],
      saveButton: false,
      newPromoter: "",
      newPromoterValue: "",
      promoters: [],
      confirmModalActive: false,
      email: ""
    };
  },
  methods: {
    open(item) {
      this.email = item.email;
      this.name = item.name;
      this.$axios
        .post("/company/buyer-orders/" + this.brand, {
          email: item.email
        })
        .then(response => {
          this.orders = response.data.orders;
          this.promoters = response.data.promoters;
        });
      setTimeout(() => {
        this.active = true;
      }, 50);
    },
    close() {
      this.active = false;
      this.orders = [];
    },
    updatePromoterLocal(input) {
      this.newPromoterValue = input;
      this.newPromoter = input.value;
      this.saveButton = true;
    },
    updatePromoterEvent() {
      // Display a confirmation dialog
      var userConfirmed = window.confirm(
        "Do you want to assign new Promoter to this buyer?"
      );
      let newPromoter = this.newPromoter;
      this.newPromoter = "";
      this.newPromoterValue = "";
      if (userConfirmed) {
        this.$emit("update-promoter", newPromoter, this.email);
      }
      this.close();
    }
  }
};
</script>

<style>
.v-application--wrap {
  height: auto;
  min-height: 40vh;
}
</style>

<style lang="less">
.modal .modal-body {
  padding-top: 0 !important;
}
</style>
