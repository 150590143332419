<template>
  <div class="bank-tab-wrapper">
    <div class="bank-tab">
      <div class="bank-title">
        <h4>Secure Banking Information Submission</h4>
        <div>
          You may fill this in at a later time but it's best to handle this now
          so it's not forgetter, resulting in a late payout. This information is
          required for you to receive payouts.
        </div>
      </div>
      <div class="info-input-wrapper">
        <div >
          <!-- :class="{ read_only: readOnly }" -->
          <diyobo-input
            type="dropdown"
            label="Country"
            placeholder="Select Your Country"
            required
            noStars
            v-model="country"
            :options="countries"
            :val="country"
            :error="error('country')"
            :bus="countryBus"
            :readOnly="readOnly"
            @input="payoutTypeSelection"
          />
        </div>

        <diyobo-input
          type="dropdown"
          label="Payout Method"
          placeholder="Select Payout Method"
          required
          v-model="payoutType"
          :options="country === 'Canada' ? payoutTypesCA : payoutTypesUS"
          :val="payoutType"
          :bus="payoutBus"
          :readOnly="readOnly"
        />
      </div>
      <div v-if="payoutType === 'Direct Deposit'" class="bank-info">
        <div class="canada-input-info" v-if="country === 'Canada'">
          <div class="bank-input-info account">
            <span class="bank-input-title">
              Account Number
              <span class="star-ctn" :class="{ read_only: readOnly }">
                <b>*</b>
              </span>
            </span>
            <div class="bank-input-wrapper">
              <diyobo-input
                v-if="!readOnly"
                type="money"
                placeholder="XXXXXXXXXXXX"
                v-model="accountNum"
                required
                noStars
                :val="accountNum"
                :error="error('accountNum')"
              />
              <div v-else class="bank-info-saved">
                {{ accountNum }}
              </div>
            </div>
          </div>
          <div class="bank-input-info institution">
            <span class="bank-input-title">
              Institution Number
              <span class="star-ctn" :class="{ read_only: readOnly }">
                <b>*</b>
              </span>
            </span>
            <div class="bank-input-wrapper">
              <diyobo-input
                v-if="!readOnly"
                type="money"
                placeholder="XXX"
                v-model="insitNum"
                required
                noStars
                :val="insitNum"
                :error="error('insitNum')"
              />
              <div v-else class="bank-info-saved">
                {{ insitNum }}
              </div>
            </div>
          </div>
          <div class="bank-input-info transit">
            <span class="bank-input-title">
              Transit Number
              <span class="star-ctn" :class="{ read_only: readOnly }">
                <b>*</b>
              </span>
            </span>
            <div class="bank-input-wrapper">
              <diyobo-input
                v-if="!readOnly"
                type="money"
                placeholder="XXXXX"
                required
                noStars
                v-model="tNum"
                :val="tNum"
                :error="error('tNum')"
              />
              <div v-else class="bank-info-saved">
                {{ tNum }}
              </div>
            </div>
          </div>
        </div>
        <div class="american-input-info" v-if="country === 'United States'">
          <div class="bank-input-info account">
            <span class="bank-input-title">
              Account Number
              <span class="star-ctn" :class="{ read_only: readOnly }">
                <b>*</b>
              </span>
            </span>
            <div class="bank-input-wrapper">
              <diyobo-input
                v-if="!readOnly"
                type="money"
                placeholder="XXXXXXXXXXXX"
                required
                noStars
                v-model="accountNum"
                :val="accountNum"
                :error="error('accountNum')"
              />
              <div v-else class="bank-info-saved">
                {{ accountNum }}
              </div>
            </div>
          </div>
          <div class="bank-input-info route">
            <span class="bank-input-title">
              Route Number
              <span class="star-ctn" :class="{ read_only: readOnly }">
                <b>*</b>
              </span>
            </span>
            <div class="bank-input-wrapper">
              <diyobo-input
                v-if="!readOnly"
                type="money"
                placeholder="XXXXXXXXXXXX"
                required
                noStars
                v-model="routeNum"
                :val="routeNum"
                :error="error('routeNum')"
              />
              <div v-else class="bank-info-saved">
                {{ routeNum }}
              </div>
            </div>
          </div>
          <div class="bank-input-info name">
            <span class="bank-input-title">
              Name of Bank
              <span class="star-ctn" :class="{ read_only: readOnly }">
                <b>*</b>
              </span>
            </span>
            <div class="bank-input-wrapper">
              <diyobo-input
                v-if="!readOnly"
                type="text"
                placeholder="XXXXXXXXXX"
                required
                noStars
                v-model="bankName"
                :val="bankName"
                :error="error('bankName')"
              />
              <div v-else class="bank-info-saved">
                {{ bankName }}
              </div>
            </div>
          </div>
          <!-- <div class="bank-input-info account-type">
            <span class="bank-input-title">
              Account Type
              <span class="star-ctn" :class="{ read_only: readOnly }">
                <b>*</b>
              </span>
            </span>
            <div class="bank-input-wrapper dropdown-container">
              <div :class="{ read_only: readOnly }">
                <diyobo-input
                  type="dropdown-no-label-small"
                  placeholder="XXXXXXXXXXXX"
                  required
                  noStars
                  v-model="accountType"
                  :val="accountType"
                  :options="accountTypeOptions"
                  :error="error('accountType')"
                  :bus="accountTypeBus"
                />
              </div>
              <div v-if="readOnly" class="bank-info-saved">
                {{ accountType }}
              </div>
            </div>
          </div> -->
          <!-- <div class="bank-input-info branch-name">
            <span class="bank-input-title">
              Branch Name
              <span class="star-ctn" :class="{ read_only: readOnly }">
                <b>*</b>
              </span>
            </span>
            <div class="bank-input-wrapper dropdown-container">
              <diyobo-input
                v-if="!readOnly"
                type="text"
                placeholder="XXXXXXXXXXXX"
                required
                noStars
                v-model="branchName"
                :val="branchName"
                :error="error('branchName')"
                :readOnly="readOnly"
              />
              <div v-else class="bank-info-saved">
                {{ branchName }}
              </div>
            </div>
          </div> -->
          <!-- <div class="bank-input-info branch-address">
            <span class="bank-input-title">
              Branch Address
              <span class="star-ctn" :class="{ read_only: readOnly }">
                <b>*</b>
              </span>
            </span>
            <div class="bank-input-wrapper dropdown-container">
              <google-autocomplete-input
                v-if="!readOnly"
                id="branchAddress"
                type="location"
                placeholder="XXXXXXXXXXXX"
                required
                noStars
                v-model="branchAddress.route"
                :val="branchAddress.route"
                :error="error('branchAddress')"
                :bus="accountTypeBus"
                @updated="getLocation"
              />
              <div
                v-else
                class="bank-info-saved"
                :class="{ read_only: !readOnly }"
              >
                {{ branchAddress.route + " - " + branchAddress.postal_code }}
              </div>
            </div>
          </div> -->
        </div>
        <!-- <div class="bank-input-info type">
          <span class="bank-input-title">
            Individual or Company Account?
            <span class="star-ctn" :class="{ read_only: readOnly }">
              <b>*</b>
            </span>
          </span>
          <div class="bank-input-wrapper dropdown-container">
            <div :class="{ read_only: readOnly }">
              <diyobo-input
                type="dropdown-no-label-small"
                placeholder="Individual/Company"
                required
                noStars
                v-model="bankType"
                :options="bankTypeOptions"
                :val="bankType"
                :error="error('bankType')"
                :bus="typeBus"
              />
            </div>
            <div class="bank-info-saved" :class="{ read_only: !readOnly }">
              {{ bankType }}
            </div>
          </div>
        </div> -->
        <!-- <div class="bank-input-info date">
          <span class="bank-input-title">
            Date Of Birth
            <span class="star-ctn" :class="{ read_only: readOnly }">
              <b>*</b>
            </span>
          </span>
          <div class="bank-input-wrapper">
            <diyobo-input
              v-if="!readOnly"
              id="date-of-birth"
              type="text"
              placeholder="yyyy/mm/dd"
              required
              noStars
              numsOnly
              v-model="dateOfBirth"
              :val="dateOfBirth"
              :max="10"
              :error="error('dateOfBirth')"
            />
            <div v-else class="bank-info-saved">
              {{ dateOfBirth }}
            </div>
          </div>
        </div> -->
        <!-- <div class='bank-input-info sin'>
          <span class='bank-input-title  '>SIN/SSN<span class='star-ctn' :class='{read_only: readOnly}'><b>*</b></span></span>
          <div class='bank-input-wrapper' >
            <diyobo-input 
              type='money' 
              :placeholder="'XXXXXXXXXX'" 
              v-model="sNum" 
              :val='sNum'
              :required='true'
              :noStars='true'
              :error="error('sNum')"
              ref='sNum'
              v-if="!readOnly"
            />
            <div class='bank-info-saved  ' v-if="readOnly" :sNum='sNum'>{{sNum}}</div>
          </div>
        </div>-->
        <!-- <div class="bank-input-info email">
          <span class="bank-input-title">
            Account Email
            <span class="star-ctn" :class="{ read_only: readOnly }">
              <b>*</b>
            </span>
          </span>
          <div class="bank-input-wrapper">
            <diyobo-input
              v-if="!readOnly"
              type="email"
              placeholder="XXXXXXX123@email.com"
              required
              noStars
              v-model="aEmail"
              :val="aEmail"
              :error="error('aEmail')"
            />
            <div v-else class="bank-info-saved">
              {{ aEmail }}
            </div>
          </div>
        </div> -->
        <div class="bank-input-info name">
          <span class="bank-input-title">
            Name of Account Holder
            <span class="star-ctn" :class="{ read_only: readOnly }">
              <b>*</b>
            </span>
          </span>
          <div class="bank-input-wrapper">
            <diyobo-input
              v-if="!readOnly"
              type="text"
              placeholder="XXXXXXXXXX"
              required
              noStars
              v-model="name"
              :val="name"
              :error="error('name')"
            />
            <div v-else class="bank-info-saved">
              {{ name }}
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="payoutType === 'eTransfer'" class="bank-info">
        <div class="bank-input-info country">
          <span class="bank-input-title">
            Email
            <span class="star-ctn" :class="{ read_only: readOnly }">
              <b>*</b>
            </span>
          </span>
          <div class="bank-input-wrapper">
            <diyobo-input
              v-if="!readOnly"
              type="email"
              placeholder="123email@example.com"
              required
              noStars
              v-model="etransferFormValues.email"
              :val="etransferFormValues.email"
              :error="etransferFormErrors.email"
            />
            <div v-else class="bank-info-saved">
              {{ etransferFormValues.email }}
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="payoutType === 'Direct Deposit (US)'" class="bank-info">
        <!-- <div class="bank-input-info country">
          <span class="bank-input-title">
            Bank name
            <span class="star-ctn" :class="{ read_only: readOnly }">
              <b>*</b>
            </span>
          </span>
          <div class="bank-input-wrapper">
            <diyobo-input
              v-if="!readOnly"
              type="text"
              required
              noStars
              v-model="etransferFormValues.email"
              :val="etransferFormValues.email"
              :error="etransferFormErrors.email"
            />
            <div v-else class="bank-info-saved">
              {{ etransferFormValues.email }}
            </div>
          </div>
        </div> -->
      </div>
      <div v-else-if="payoutType === 'SWIFT'" class="bank-info">
        <div class="bank-input-info name">
          <span class="bank-input-title">
            Name of Account Holder
            <span class="star-ctn" :class="{ read_only: readOnly }">
              <b>*</b>
            </span>
          </span>
          <div class="bank-input-wrapper">
            <diyobo-input
              v-if="!readOnly"
              type="text"
              placeholder="XXXXXXXXXX"
              required
              noStars
              v-model="swiftFormValues.accountName"
              :val="swiftFormValues.accountName"
              :error="swiftFormErrors.accountName"
            />
            <div v-else class="bank-info-saved">
              {{ swiftFormValues.accountName }}
            </div>
          </div>
        </div>
        <div class="bank-input-info name">
          <span class="bank-input-title">
            Address of Account Holder
            <span class="star-ctn" :class="{ read_only: readOnly }">
              <b>*</b>
            </span>
          </span>
          <div class="bank-input-wrapper">
            <diyobo-input
              v-if="!readOnly"
              type="text"
              placeholder="XXXXXXXXXX"
              required
              noStars
              v-model="swiftFormValues.accountAddress"
              :val="swiftFormValues.accountAddress"
              :error="swiftFormErrors.accountAddress"
            />
            <div v-else class="bank-info-saved">
              {{ swiftFormValues.accountAddress }}
            </div>
          </div>
        </div>
        <div class="bank-input-info name">
          <span class="bank-input-title">
            Name of Bank
            <span class="star-ctn" :class="{ read_only: readOnly }">
              <b>*</b>
            </span>
          </span>
          <div class="bank-input-wrapper">
            <diyobo-input
              v-if="!readOnly"
              type="text"
              placeholder="XXXXXXXXXX"
              required
              noStars
              v-model="swiftFormValues.bankName"
              :val="swiftFormValues.bankName"
              :error="swiftFormErrors.bankName"
            />
            <div v-else class="bank-info-saved">
              {{ swiftFormValues.bankName }}
            </div>
          </div>
        </div>
        <div class="bank-input-info name">
          <span class="bank-input-title">
            Address of Bank
            <span class="star-ctn" :class="{ read_only: readOnly }">
              <b>*</b>
            </span>
          </span>
          <div class="bank-input-wrapper">
            <diyobo-input
              v-if="!readOnly"
              type="text"
              placeholder="XXXXXXXXXX"
              required
              noStars
              v-model="swiftFormValues.bankAddress"
              :val="swiftFormValues.bankAddress"
              :error="swiftFormErrors.bankAddress"
            />
            <div v-else class="bank-info-saved">
              {{ swiftFormValues.bankAddress }}
            </div>
          </div>
        </div>
        <div class="bank-input-info name">
          <span class="bank-input-title">
            SWIFT Code/BIC
            <span class="star-ctn" :class="{ read_only: readOnly }">
              <b>*</b>
            </span>
          </span>
          <div class="bank-input-wrapper">
            <diyobo-input
              v-if="!readOnly"
              type="text"
              placeholder="XXXXXXXXXX"
              required
              noStars
              v-model="swiftFormValues.bic"
              :val="swiftFormValues.bic"
              :error="swiftFormErrors.bic"
            />
            <div v-else class="bank-info-saved">
              {{ swiftFormValues.bic }}
            </div>
          </div>
        </div>
        <div class="bank-input-info name">
          <span class="bank-input-title">
            Account Number/IBAN
            <span class="star-ctn" :class="{ read_only: readOnly }">
              <b>*</b>
            </span>
          </span>
          <div class="bank-input-wrapper">
            <diyobo-input
              v-if="!readOnly"
              type="text"
              placeholder="XXXXXXXXXX"
              required
              noStars
              v-model="swiftFormValues.iban"
              :val="swiftFormValues.iban"
              :error="swiftFormErrors.iban"
            />
            <div v-else class="bank-info-saved">
              {{ swiftFormValues.iban }}
            </div>
          </div>
        </div>
      </div>
      <div class="btn-wrapper">
        <diyobo-button
          class="reveal-button"
          v-if="!revealInfo"
          type="secondary"
          :txt="btnType"
          v-on:click="chooseFunc"
        />
        <!-- <button
          class="bank-btn"
          v-if="!revealInfo"
          :btnType="btnType"
          v-on:click="chooseFunc"
        >
          {{ btnType }}
        </button> -->
        <diyobo-button
          class="hideBtn"
          :txt="hideBtn"
          type="secondary"
          v-on:click="cancelFunc"
          v-if="revealInfo"
        />
        <diyobo-button
          class="edit-button"
          :txt="editBtn"
          type="secondary"
          v-on:click="editFunc"
          v-if="revealInfo"
        />
        <!-- <button
          class="bank-btn hide cancel"
          v-if="revealInfo"
          :hideBtn="hideBtn"
          v-on:click="cancelFunc"
        >
          {{ hideBtn }}
        </button> -->
        <!-- <button
          class="bank-btn edit save"
          v-if="revealInfo"
          :editBtn="editBtn"
          v-on:click="editFunc"
        >
          {{ editBtn }}
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import DiyoboButton from "@/components/DiyoboButton.vue";

import DiyoboInput from "@/components/DiyoboInput.vue";
import GoogleAutocompleteInput from "@/components/GoogleAutocompleteInput.vue";
import ErrorList from "../components/ErrorList.vue";
import diyoboCalendar from "./diyoboCalendar";
import * as yup from "yup";

const ETRANSFER_VALIDATION_SCHEMA = yup.object({
  email: yup
    .string()
    .email()
    .required("Email is required.")
    .matches(
      /^[a-zA-Z0-9!#$%&'*+-/=?^_`{|}~]+@{1}([a-zA-Z0-9]+\.{1}){1,2}[a-zA-Z0-9]+$/,
      "Please enter a valid email address"
    )
});

const SWIFT_VALIDATION_SCHEMA = yup.object({
  accountName: yup.string().required("Account Name is required."),
  accountAddress: yup.string().required("Account Address is required."),
  bankName: yup.string().required("Bank Name is required."),
  bankAddress: yup.string().required("Bank Address is required."),
  bic: yup.string().required("BIC is required."),
  iban: yup.string().required("IBAN is required.")
});

export default {
  components: {
    DiyoboInput,
    GoogleAutocompleteInput,
    ErrorList,
    diyoboCalendar,
    DiyoboButton
  },
  props: {
    info: Object,
    companyBus: Object,
    brandUrl: String
  },
  data() {
    return {
      countries: [
        { label: "Canada", value: "Canada" },
        {
          label: "United States",
          value: "United States"
        }
      ],
      bankTypeOptions: [
        { label: "Individual", value: "Individual" },
        { label: "Company", value: "Company" }
      ],
      accountTypeOptions: [
        { label: "Chequing", value: "Chequing" },
        { label: "Savings", value: "Savings" }
      ],
      country: "",
      routeNum: "",
      accountNum: "",
      accountType: "",
      branchName: "",
      branchAddress: "",
      insitNum: "",
      tNum: "",
      bankType: "",
      bankName: "",
      dateOfBirth: "",
      sNum: "",
      aEmail: "",
      caBank: [
        { name: "country", display: "Country", error: false },
        { name: "accountNum", display: "Account Number", error: false },
        { name: "insitNum", display: "Institution Number", error: false },
        { name: "tNum", display: "Transit Number", error: false },
        // { name: "bankType", display: "Bank Type", error: false },
        // { name: "dateOfBirth", display: "Date of Birth", error: false },
        // { name: "aEmail", display: "Account Email", error: false },
        { name: "name", display: "Account Holder Name", error: false }
      ],
      usBank: [
        { name: "country", display: "Country", error: false },
        { name: "accountNum", display: "Account Number", error: false },
        { name: "routeNum", display: "Route Number", error: false },
        { name: "bankName", display: "Name of Bank", error: false },
        // { name: "accountType", display: "Account Type", error: false },
        // { name: "branchName", display: "Branch Name", error: false },
        // { name: "branchAddress", display: "Branch Address", error: false },
        // { name: "bankType", display: "Bank Type", error: false },
        // { name: "dateOfBirth", display: "Date of Birth", error: false },
        // { name: "aEmail", display: "Account Email", error: false },
        { name: "name", display: "Account Holder Name", error: false }
      ],
      errors: [
        { name: "country", display: "Country", error: false },
        { name: "bankType", display: "Bank Type", error: false },
        { name: "dateOfBirth", display: "Date of Birth", error: false },
        // { name: 'sNum', display: 'SIN/SSN', error: false},
        { name: "name", display: "Account Holder Name", error: false },
        { name: "aEmail", display: "Account Email", error: false },
        { name: "accountNum", display: "Account Number", error: false },
        { name: "routeNum", display: "Route Number", error: false },
        { name: "bankName", display: "Name of Bank", error: false },
        { name: "accountType", display: "Account Type", error: false },
        { name: "branchName", display: "Branch Name", error: false },
        { name: "branchAddress", display: "Branch Address", error: false },
        { name: "bankType", display: "Bank Type", error: false }
      ],
      btnType: "Save",
      name: "",
      infoSaved: false,
      editInfo: false,
      revealInfo: false,
      readOnly: false,
      countryBus: new Vue(),
      accountTypeBus: new Vue(),
      payoutBus: new Vue(),
      typeBus: new Vue(),
      oldBankObj: this.info && this.info.country ? this.info : {},
      payoutType: null,
      payoutTypesCA: [
        { label: "Direct Deposit", value: "Direct Deposit" },
        { label: "eTransfer", value: "eTransfer" }
        // {label: "Swift", value: "SWIFT"}
        //  {label: "Direct Deposit (US)", value: "Direct Deposit (US)"}
      ],
      payoutTypesUS: [
        { label: "Direct Deposit", value: "Direct Deposit" }
        // {label: "Swift", value: "SWIFT"}
        //  {label: "Direct Deposit (US)", value: "Direct Deposit (US)"}
      ],
      etransferFormValues: {
        email: ""
      },
      etransferFormErrors: {
        email: ""
      },
      swiftFormValues: {
        accountName: "",
        accountAddress: "",
        bankName: "",
        bankAddress: "",
        bic: "",
        iban: ""
      },
      swiftFormErrors: {
        accountName: "",
        accountAddress: "",
        bankName: "",
        bankAddress: "",
        bic: "",
        iban: ""
      }
    };
  },
  methods: {
    payoutTypeSelection() {
      if (this.country === "United States") {
        this.payoutType = "Direct Deposit";
      }
    },
    saveBankInfo(objToSend) {
      this.$axios.post("/company/save-bank-info", objToSend).then(response => {
        let data = response.data;

        if (response.status == 200) {
          if (data["failCheck"]) {
            didIFail = true;
            this.$parent.sliderModal = true;
            this.$parent.screenSliderMsg = "Failed to save to the back-end";
          } else {
            this.$store.commit("updateUserProperty", {
              property: "plannerPayouts",
              value: 1
            });
            if (data.companyInfo) {
              this.hideData(data.companyInfo.bankInfo);
            } else {
              this.hideData(data.bankInfo);
            }
            // let objToValidate = ["companyInfo", objToSend];
            // this.$emit("step-msg", ...objToValidate);
            let objToValidate = ["bankInfo", objToSend];
            this.$emit("send-data", ...objToValidate);
          }
        }
      });
    },
    validateAt(field) {
      if (this.payoutType === "eTransfer") {
        ETRANSFER_VALIDATION_SCHEMA.validateAt(field, this.etransferFormValues)
          .then(() => {
            this.etransferFormErrors[field] = "";
          })
          .catch(err => {
            this.etransferFormErrors[field] = err.message;
          });
      } else if (this.payoutType === "SWIFT") {
        SWIFT_VALIDATION_SCHEMA.validateAt(field, this.swiftFormValues)
          .then(() => {
            this.swiftFormErrors[field] = "";
          })
          .catch(err => {
            this.swiftFormErrors[field] = err.message;
          });
      }
    },
    error(input) {
      let error = "";
      if (this.country === "Canada") {
        error = this.caBank.find(e => e.name === input);
      } else if (this.country === "United States") {
        error = this.usBank.find(e => e.name === input);
      } else {
        error = this.errors.find(e => e.name === input);
      }

      return error && error.error;
    },
    async validate() {
      if (this.payoutType === "Direct Deposit") {
        if (this.country === "Canada") {
          this.caBank.forEach(ele => {
            ele.errors = [];
            if (ele.name === "dateOfBirth") {
              if (!this[ele.name]) {
                ele.error = true;
              } else {
                let dateCheck = new Date(this[ele.name]);
                if (dateCheck) {
                  ele.error = false;
                }
              }
            } else {
              if (!this[ele.name]) {
                ele.error = true;
              } else {
                ele.error = false;
              }
            }
            if (ele.error) {
              ele.errors.push(`${ele.display} is Required`);
            }
          });
          return !this.caBank.some(e => e.error);
        } else if (this.country === "United States") {
          this.usBank.forEach(ele => {
            ele.errors = [];
            if (ele.name === "dateOfBirth") {
              if (!this[ele.name]) {
                ele.error = true;
              } else {
                let dateCheck = new Date(this[ele.name]);
                if (dateCheck) {
                  ele.error = false;
                }
              }
            } else {
              if (!this[ele.name]) {
                ele.error = true;
              } else {
                ele.error = false;
              }
            }
            if (ele.error) {
              ele.errors.push(`${ele.display} is Required`);
            }
          });
          return !this.usBank.some(e => e.error);
        } else {
          this.errors.forEach(ele => {
            ele.errors = [];
            if (ele.name == "dateOfBirth") {
              if (!this[ele.name]) {
                ele.error = true;
              } else {
                let dateCheck = new Date(this[ele.name]);
                if (dateCheck) {
                  ele.error = false;
                }
              }
            } else {
              if (!this[ele.name]) {
                ele.error = true;
              } else {
                ele.error = false;
              }
            }
            if (ele.error) {
              ele.errors.push(`${ele.display} is Required`);
            }
          });
          return !this.errors.some(e => e.error);
        }
      } else if (this.payoutType === "eTransfer") {
        try {
          await ETRANSFER_VALIDATION_SCHEMA.validate(this.etransferFormValues, {
            abortEarly: false
          });

          return true;
        } catch (error) {
          error.inner.forEach(err => {
            this.etransferFormErrors[err.path] = err.message;
          });

          return false;
        }
      } else if (this.payoutType === "SWIFT") {
        try {
          await SWIFT_VALIDATION_SCHEMA.validate(this.swiftFormValues, {
            abortEarly: false
          });

          return true;
        } catch (error) {
          error.inner.forEach(err => {
            this.swiftFormErrors[err.path] = err.message;
          });

          return false;
        }
      }

      return false;
    },
    chooseFunc() {
      if (!this.infoSaved) {
        this.saveData();
      } else if (!this.editInfo) {
        this.revealData();
      }
    },
    async saveData() {
      let didIFail = false;

      let objToSend = {
        bankObj: {},
        brandUrl: "",
        type: "bankInfo"
      };

      if (this.payoutType === "Direct Deposit") {
        if (this.country === "Canada") {
          this.caBank.forEach(ele => {
            objToSend.bankObj[ele.name] = this[ele.name];
          });
        } else {
          this.usBank.forEach(ele => {
            objToSend.bankObj[ele.name] = this[ele.name];
          });
        }
      } else if (this.payoutType === "eTransfer") {
        objToSend.bankObj = this.etransferFormValues;
      } else if (this.payoutType === "SWIFT") {
        objToSend.bankObj = this.swiftFormValues;
      }

      objToSend.userEmail = this.userEmail;
      objToSend.bankObj.payoutType = this.payoutType;
      objToSend.brandUrl = this.brandUrl;

      if (await this.validate()) {
        this.saveBankInfo(objToSend);
        this.$emit("updateShowContinueBar", objToSend);
        // this.$emit("send-data", ...objToValidate);
      } else {
      }
      return didIFail;
    },
    async saveDataInner() {
      let didIFail = false;

      let objToSend = {
        bankObj: {},
        brandUrl: "",

        type: "bankInfo"
      };

      if (this.payoutType === "Direct Deposit") {
        if (this.country === "Canada") {
          this.caBank.forEach(ele => {
            objToSend.bankObj[ele.name] = this[ele.name];
          });
        } else {
          this.usBank.forEach(ele => {
            objToSend.bankObj[ele.name] = this[ele.name];
          });
        }
      } else if (this.payoutType === "eTransfer") {
        objToSend.bankObj = this.etransferFormValues;
      } else if (this.payoutType === "SWIFT") {
        objToSend.bankObj = this.swiftFormValues;
      }

      objToSend.userEmail = this.userEmail;
      objToSend.bankObj.payoutType = this.payoutType;
      objToSend.brandUrl = this.brandUrl;

      if (await this.validate()) {
        this.saveBankInfo(objToSend);
      } else {
      }
      return didIFail;
    },
    hideData(bankObj) {
      this.infoSaved = true;
      this.editInfo = false;
      this.readOnly = true;
      if (bankObj.payoutType || bankObj.country) {
        this.payoutType = bankObj.payoutType || "Direct Deposit";

        this.payoutBus.$emit("set-value-placeholder", {
          value: this.payoutType,
          placeholder: this.payoutType
        });
      }
      // let countryObj = {
      //   value: this.country,
      //   placeholder: this.country
      // }
      // this.countryBus.$emit('set-value-placeholder', countryObj)
      this.country = "***";
      this.tNum = "***";
      this.insitNum = "***";
      this.routeNum = "***";
      this.branchName = "***";
      this.branchAddress = "***";
      this.accountType = "***";
      this.accountNum = "***";
      this.sNum = "***";
      this.dateOfBirth = "***";
      this.name = "***";
      this.bankType = "***";
      this.bankName = "***";
      this.aEmail = "***";

      this.etransferFormValues = {
        email: "***"
      };

      this.swiftFormValues = {
        accountName: "***",
        accountAddress: "***",
        bic: "***",
        iban: "***",
        bankName: "***",
        bankAddress: "***"
      };
      // let bTypeObj = {
      //   value: this.bankType,
      //   placeholder: this.bankType
      // }
      // this.typeBus.$emit('set-value-placeholder', bTypeObj)
      this.oldBankObj = bankObj;
      this.revealInfo = false;
    },
    revealData(isMounted = false) {
      let objToSend = {
        url: this.brandUrl,
        userEmail: this.userEmail,
        type: "bankInfo"
      };
      let bankObj;
      this.$axios.post("/company/get-info", objToSend).then(response => {
        if (response.status === 200) {
          bankObj = response.data;
          if (isMounted) {
            if (!Object.keys(bankObj).length) {
              this.revealInfo = true;
              this.editInfo = true;
              this.readOnly = false;
            }
          } else {
            if (!bankObj["failCheck"]) {
              this.showData(bankObj);
            } else {
              this.$parent.sliderModal = true;
              this.$parent.screenSliderMsg = "Failed to get your banking info";
            }
          }
        }
      });
    },
    showData(bankObj) {
      this.country = bankObj.country;
      this.accountNum = bankObj.aNum;
      this.sNum = bankObj.sNum;
      this.dateOfBirth = bankObj.dateOfBirth;
      this.name = bankObj.name;
      this.tNum = bankObj.tNum;
      this.insitNum = bankObj.iNum;
      this.routeNum = bankObj.rNum;
      this.bankName = bankObj.bankName || "";
      this.branchName = bankObj.branchName;
      this.branchAddress = bankObj.branchAddress;
      this.accountType = bankObj.aType;
      this.bankType = bankObj.bType;
      this.aEmail = bankObj.aEmail;

      this.etransferFormValues = bankObj;
      this.swiftFormValues = bankObj;

      this.revealInfo = true;
    },
    getLocation(val) {
      this.branchAddress = val;
    },
    clearData() {
      this.errors.forEach(ele => {
        if (ele.name != "country") this[ele.name] = "";
      });
    },
    cancelFunc() {
      if (this.editInfo) {
        this.editInfo = false;
        this.readOnly = true;
        this.errors = [
          { name: "country", display: "Country", error: false },
          { name: "bankType", display: "Bank Type", error: false },
          { name: "dateOfBirth", display: "Date of Birth", error: false },
          // { name: "sNum", display: "SIN/SSN", error: false },
          { name: "name", display: "Account Holder Name", error: false }
        ];
      } else {
        this.readOnly = true;
      }
      this.hideData(this.oldBankObj);
    },
    editFunc() {
      if (this.editInfo) {
        this.saveDataInner();
      } else {
        this.readOnly = false;
        this.editInfo = true;
        let countryObj = {
          value: this.country,
          placeholder: this.country
        };
        this.countryBus.$emit("set-value-placeholder", countryObj);
        let bTypeObj = {
          value: this.bankType,
          placeholder: this.bankType
        };
        this.typeBus.$emit("set-value-placeholder", bTypeObj);
        if (this.oldBankObj.country == "United States") {
          let accountTypeObj = {
            value: this.accountType,
            placeholder: this.accountType
          };
          this.accountTypeBus.$emit("set-value-placeholder", accountTypeObj);
        }
      }
    },
    injectErrors(val) {
      if (val == "Canada") {
        this.errors.splice(0, this.errors.length);
        this.errors.push(
          ...[
            { name: "country", display: "Country", error: false },
            { name: "accountNum", display: "Account Number", error: false },
            { name: "insitNum", display: "Institution Number", error: false },
            { name: "tNum", display: "Transit Number", error: false },
            { name: "bankType", display: "Bank Type", error: false },
            { name: "dateOfBirth", display: "Date of Birth", error: false },
            // { name: 'sNum', display: 'SIN/SSN', error: false},
            { name: "name", display: "Account Holder Name", error: false },
            { name: "aEmail", display: "Account Email", error: false }
          ]
        );
      } else if (val == "United States") {
        this.errors.splice(0, this.errors.length);
        this.errors.push(
          ...[
            { name: "country", display: "Country", error: false },
            { name: "accountNum", display: "Account Number", error: false },
            { name: "routeNum", display: "Routing Number", error: false },
            { name: "accountType", display: "Account Type", error: false },
            { name: "branchName", display: "Branch Name", error: false },
            { name: "branchAddress", display: "Branch Address", error: false },
            { name: "bankType", display: "Bank Type", error: false },
            { name: "dateOfBirth", display: "Date of Birth", error: false },
            // { name: 'sNum', display: 'SIN/SSN', error: false},
            { name: "name", display: "Account Holder Name", error: false },
            { name: "aEmail", display: "Account Email", error: false }
          ]
        );
      }
    },
    updateDOB(date) {
      this.dateOfBirth = date;
    }
  },
  computed: {
    userEmail() {
      return this.$store.state.user.email;
    },
    hideBtn() {
      if (this.editInfo) {
        return "Cancel";
      } else {
        return "Hide";
      }
    },
    editBtn() {
      if (this.editInfo) {
        return "Save";
      } else {
        return "Edit";
      }
    }
  },
  watch: {
    dateOfBirth(val) {
      if (val && (val.length === 4 || val.length === 7)) {
        this.dateOfBirth += "/";
      }
    },
    infoSaved(val) {
      if (val) {
        this.btnType = "Reveal";
      } else {
        this.btnType = "Save";
      }
    },
    country(val) {
      if (!this.infoSaved) {
        this.clearData();
      } else if (this.infoSaved && this.editInfo) {
        this.clearData();
      }
      this.injectErrors(val);
    },
    info(val) {
      this.hideData(val);
    }
  },
  mounted() {
    this.revealData(true);

    if (this.companyBus) {
      this.companyBus.$on("save-company-info", args => {
        if (Object.keys(this.info).length === 0) {
          // this.saveData();
          let objToSend = ["bankInfo", false];
          // this.$emit("send-to-errors", ...objToSend);
        } else {
          let objToSend = ["bankInfo", false];
          // this.$emit("send-to-errors", ...objToSend);
        }
      });
    }
    // }
  }
};
</script>

<style lang="less">
.bank-tab-wrapper {
  margin: auto;
  padding: 1.5em;
  max-width: 650px;
  margin-top: 40px;
  border-radius: 5px;
  box-shadow: var(--table-shadow);
  background-color: var(--table-bg);

  .bank-tab {
    .bank-title {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-bottom: 15px;
      text-align: center;

      h3 {
        margin-bottom: 5px;
      }
    }

    .btn-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 22px;
      margin-bottom: 18px;

      .reveal-button,
      .edit-button,
      .hideBtn {
        background-color: var(--primary-green);
      }

      .hideBtn {
        margin-right: 20px;
      }

      .bank-btn {
        outline: none;
        padding: 0.75em 2em;
        width: 280px;
        background-color: #4cac55;
        background: linear-gradient(#4cac55, #39793f);
        color: #fff;
        border-color: #5fb469;
        padding: 8px 18px;
        position: relative;
        border: 1px solid #5fb469;
        border-radius: 5px;
        outline: none;
        font-size: 1em;

        font-weight: 500;
        cursor: pointer;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          width: 100%;
          height: 100%;
          background: linear-gradient(#5bc465, #448b4b);
          opacity: 0;
          transition: 0.4s;
          border-radius: 5px;
        }

        &:hover::before {
          opacity: 1;
        }
      }

      //end bank-btn:focus
      .hide {
        margin-right: 8.5px;
      }

      .edit {
        margin-left: 8.5px;
      }

      .hide,
      .edit {
        width: 130px;
      }
    }

    //end btn-wrapper

    .bank-input-wrapper {
      padding: 0.5em 0.25em;
      width: 45%;
      // font-size: 1em;
      // border: 1px solid #000;
      border: 1px solid transparent;
      border-radius: 0;
      -webkit-appearance: none;
      transition: border-color 600ms ease;

      input {
        padding: 0px;
      }
    }

    .bank-input-info.date {
      .event-date {
        .date-picker-wrapper {
          top: 25px;
        }
      }
    }

    //end h3 or bank-payment-option
    .bank-input-info {
      // max-height: 60px;
      display: flex;
      justify-content: space-between;
      margin: 0.25em 0;
      padding: 0px;
      box-sizing: border-box;

      .bank-input-title {
        padding: 0.5em 0.25em;
        font-weight: 600;
        align-self: center;
        position: relative;

        .star-ctn {
          position: absolute;
          top: 5px;
          right: -5px;
          color: #43b777;
        }
      }

      //end bank-input-title
      label {
        display: none;
      }

      .input-wrapper {
        margin: 0;
      }

      .read_only {
        display: none;
      }
    }

    div.bank-input-wrapper.dropdown-container {
      //position: relative;
      div.input-wrapper:first-child {
        margin: 0;

        label:first-child {
          display: block;
          position: absolute;
          top: -15px;
        }

        div.icon-down-no-label-small:first-child {
          // top: 1%;
          z-index: 10;
          left: 91%;
        }
      }
    }
  }
}

@media only screen and (max-width: 710px) {
  .information-wrapper .info-input-wrapper {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .information-wrapper .general-info-wrapper .general-info .general-info-title {
    max-width: initial;
  }

  .information-wrapper
    .secondary-info-wrapper
    .secondary-info
    .secondary-info-title {
    max-width: initial;
  }

  // .information-wrapper .info-input-wrapper {
  //   width: 100%;
  // }
  .general-title {
    width: 100%;
    color: var(--headers);
  }

  .bank-tab-wrapper {
    padding: 0.5em;

    .bank-tab {
      .bank-title {
        h3 {
          font-size: 20px;
        }
      }

      .bank-info {
        font-size: 14px !important;

        .bank-input-info {
          .bank-input-title {
            width: 40%;
          }

          .bank-input-wrapper {
            width: 60%;
          }

          font-size: 13px;
        }
      }
    }
  }
}
</style>
