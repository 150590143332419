<template>
  <div class="information-wrapper">
    <alert-modal ref="alert" :closable="false"/>
    <processing-modal :active="processing" v-on:cancel="closeModal()"/>

    <div class="content-path">
      <breadcrumbs :crumbs="breadcrumbs"/>
    </div>
    <div class="general-info-wrapper">
      <div class="general-info">
        <FormulateForm
          name="generalInfo"
          v-model="generalInfo"
          :value="generalInfo"
          @submit="saveBrand"
          #default="{ isLoading }"
          autocomplete="false"
        >
          <Card class="card-container">
            <div class="general-info-title">
              <h3 class="general-title">General Information</h3>
              <!-- <p class="general-title-text title-text b1">
              <i>
                All fields marked <strong class="star">*</strong> are Required
              </i>
            </p> -->
            </div>
            <div class="general-input-wrapper row">
              <div class="info-input-wrapper col half">
                <FormulateInput
                  type="text"
                  name="companyName"
                  label="Brand Name"
                  placeholder="Brand Name"
                  validation="required"
                  autocomplete="false"
                />
                <!-- <FormulateInput
              required
              v-model="companyName"
              :val="companyName"
              ref="company-name"
              type="text"
              :error="error('companyName')"
              sublabel="This is displayed on your Brand Page."
            /> -->
              </div>
              <div class="info-input-wrapper col half">
                <FormulateInput
                  type="text"
                  name="companyWebsite"
                  label="Brand Website"
                  placeholder="Brand Website"
                  :validation="companyWebsiteValidation"
                  @blur-context="companyWebsiteBlur"
                  autocomplete="false"
                />
                <!-- <diyobo-input
                label="Brand Website"
                placeholder="Brand Website"
                v-model="companyWebsite"
                :val="companyWebsite"
                ref="company-website"
                type="text"
                :error="error('companyWebsite')"
                sublabel="This is displayed on your Brand Page."
              /> -->
              </div>
              <div class="info-input-wrapper col half">
                <FormulateInput
                  type="tel"
                  name="companyPhone"
                  id="company-phone-number"
                  label="Phone Number"
                  placeholder="Phone Number"
                  validation=""
                  autocomplete="false"
                  :validation-messages="{
                    min: 'Invalid phone number provided.'
                  }"
                />
              </div>
              <div class="info-input-wrapper col half">
                <FormulateInput
                  type="text"
                  name="companyEmail"
                  placeholder="Email Address"
                  label="Email address"
                  :validation="emailValidation"
                  :validation-messages="emailValidationMessage"
                  autocomplete="false"
                />
              </div>
              <div class="info-input-wrapper col half">
                <google-autocomplete-input
                  label="Brand Address"
                  name="address"
                  placeholder="Brand Address"
                  v-model="generalInfo.companyAddress"
                  :val="generalInfo.companyAddress"
                  :mapType="'address'"
                  ref="company-address"
                  type="location"
                  id="company-address"
                  :error="error('companyAddress')"
                  v-on:updated="autoCompleteAddress"
                  v-on:user-input="userInput"
                />
              </div>

              <div class="info-input-wrapper col half">
                <FormulateInput
                  type="text"
                  name="city"
                  placeholder="City"
                  label="City"
                  validation="required"
                  ref="city"
                  v-model="city"
                  autocomplete="false"
                />
              </div>
              <div class="info-input-wrapper col half">
                <FormulateInput
                  type="text"
                  name="regionName"
                  label="Province / State"
                  placeholder="Province / State"
                  validation="required"
                  v-model="generalInfo.regionName"
                  ref="province-State"
                  autocomplete="false"
                />
              </div>
              <div class="info-input-wrapper col half" id="country-wrapper">
                <!--                <diyobo-input-->
                <!--                  name="country"-->
                <!--                  type="dropdown"-->
                <!--                  label="Select Country"-->
                <!--                  placeholder="Select Country"-->
                <!--                  required-->
                <!--                  :options="countries"-->
                <!--                  v-model="generalInfo.country"-->
                <!--                  :dropDownVal="generalInfo.country"-->
                <!--                  :val="generalInfo.country"-->
                <!--                  ref="country"-->
                <!--                />-->
                <FormulateInput
                  type="selectSearch"
                  label="Select Country"
                  name="country"
                  placeholder="Select Country"
                  :options="countries"
                  validation="required"
                  :validation-messages="{
                    required: 'Please select a country'
                  }"
                  v-model="generalInfo.country"
                  :val="generalInfo.country"
                  autocomplete="false"
                />
              </div>
              <div class="info-input-wrapper col half">
                <FormulateInput
                  type="text"
                  name="zipCode"
                  label="Zip / Postal Code"
                  placeholder="Zip / Postal Code"
                  :validation="`max:${postalLength},length|required`"
                  :validation-messages="{
                    max: 'Invalid Zip/Postal Code provided.'
                  }"
                  v-model="zipCode"
                  :val="zipCode"
                  autocomplete="false"
                />
                <!-- <diyobo-input
                id="postal-code"
                label="Zip/Postal Code"
                :required="true"
                :placeholder="'Zip/Postal Code'"
                :max="postalLength"
                v-model="zipCode"
                :val="zipCode"
                type="text"
                :error="error('zipCode')"
              /> -->
              </div>
              <div class="info-input-wrapper col half">
                <FormulateInput
                  name="companySize"
                  label="Number of Employees"
                  min="0"
                  placeholder="Brand Size"
                  v-model="companySize"
                  :val="companySize"
                  ref="company-size"
                  type="number"
                  validation="|number"
                  autocomplete="false"
                />
              </div>

              <div class="info-input-wrapper col half">
                <FormulateInput
                  name="eventsPerYear"
                  type="select"
                  label="Events Per Year"
                  placeholder="Select Year Group"
                  :options="eventsPerYearOptions"
                  v-model="generalInfo.eventsPerYear"
                  validation="required"
                  :val="generalInfo.eventsPerYear"
                  ref="eventsPerYear"
                  autocomplete="false"
                />
              </div>
              <div class="info-input-wrapper col half">
                <FormulateInput
                  name="ageGroup"
                  type="multiSelect"
                  label="What ages do you cater to?"
                  placeholder="Target Age Group"
                  :options="ageGroupOptions"
                  v-model="generalInfo.ageGroup"
                  validation="required"
                  :val="generalInfo.ageGroup"
                  autocomplete="false"
                />
              </div>
              <div class="info-input-wrapper col half">
                <FormulateInput
                  type="number"
                  name="estTicketSales"
                  label="Estimated Ticket Sales Yearly"
                  placeholder="Yearly Ticket Sales"
                  min="0"
                  v-model="generalInfo.estTicketSales"
                  :val="generalInfo.estTicketSales"
                  ref="eventsPerYear"
                  autocomplete="false"
                />
              </div>
              <div class="info-input-wrapper col half">
                <FormulateInput
                  type="select"
                  name="useReservedSeats"
                  :options="reservedOptions"
                  label="Will you offer reserved seating for some events?"
                  placeholder=""
                  v-model="generalInfo.useReservedSeats"
                  autocomplete="false"
                />
              </div>
            </div>
          </Card>
          <Card class="card-container">
            <div class="page-info-title">
              <h4>
                It is now time to select your brand URL. This CAN NOT be
                changed. Your brand page will display all of your events and
                your company info.
              </h4>
            </div>
            <div class="row">
              <div class="col full">
                <FormulateInput
                  type="text"
                  name="subDomain"
                  disabled
                  label="Brand URL"
                  placeholder="Brand URL"
                  validation="required"
                  :value="subdomain"
                  :help="
                    `It will be located at incredevent.com/brand/${generalInfo.subDomain}`
                  "
                  autocomplete="false"
                />
              </div>
            </div>
            <div class="btn-wrapper">
              <FormulateInput
                type="submit"
                name="Save Changes"
                :loading="isLoading"
              />
              <!-- <button
              class="primary"
              v-on:click="saveData"
              :displaySaveMsg="displaySaveMsg"
            >
              Submit
            </button> -->
            </div>
          </Card>
        </FormulateForm>
      </div>
    </div>
    <!-- <div class="secondary-info-wrapper">
      <div class="secondary-info">
        <Card>
          <div class="secondary-info-title">
            <h3 class="secondary-title">Tell Us More</h3>
          </div>
          <div class="secondary-input-wrapper row"></div>
        </Card>
      </div>
    </div> -->
  </div>
</template>

<script>
import Vue from "vue";
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import MultipleSelect from "@/components/MultipleSelect.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import {updateSession} from "../../helpers/auth";
import ProcessingModal from "@/components/modals/ProcessingModal.vue";
import ModalContent from "@/helpers/modals";
import AlertModal from "@/components/modals/AlertModal.vue";

import GoogleAutocompleteInput from "@/components/GoogleAutocompleteInput.vue";
import ErrorList from "../../components/ErrorList.vue";
import ImageUploader from "../../components/imageUploaders/TTButtonImageUploader.vue";
import {
  postalFormat,
  formatPhoneNumber,
  inputPhoneNumberOnly
} from "@/helpers/input-formats.js";
import {
  formulateEmailValidation,
  formulateEmailValidationMessage
} from "@/helpers/email.js";
import {websiteCheck} from "@/helpers/input-formats";

const HTTPS_REGEX = /^https:\/\//;
const CANADA = "Canada";
const UNITED_STATES = "United States";
export default {
  name: "general-info",
  components: {
    DiyoboInput,
    DiyoboButton,
    GoogleAutocompleteInput,
    ErrorList,
    ImageUploader,
    Breadcrumbs,
    MultipleSelect,
    ProcessingModal,
    AlertModal
  },
  props: {
    subdomain: String,
    companyBus: Object,
    info: Object,
    coordVerified: Boolean
  },
  data() {
    return {
      breadcrumbs: [
        ["Brand Dashboard", "/brands"],
        ["Edit Brand", null]
      ],
      processing: false,
      generalInfo: {
        companyName: "",
        companyWebsite: "",
        companyPhone: "",
        companyEmail: "",
        companyAddress: "",
        cityHQ: "",
        city: "",
        regionName: "",
        country: "",
        zipCode: "",
        companySize: "",
        eventsPerYear: "",
        ageGroup: "",
        estTicketSales: "",
        useReservedSeats: 'no'
      },

      selectCountry: "Select Country",
      errors: [
        {name: "country", display: "Country", error: false},
        {name: "zipCode", display: "Zip/Postal code", error: false},
        {name: "city", display: "City", error: false},
        {name: "regionName", display: "Province/State", error: false},
        {name: "companyName", display: "Company Name", error: false},
        // { name: "companyAddress", display: "Company Address", error: false },
        {name: "companyEmail", display: "Company Email", error: false},
        // { name: "companyWebsite", display: "Company Website", error: false },
        {name: "companyPhone", display: "Company Phone", error: false},
        // { name: "ageGroup", display: "Age Group", error: false },
        // { name: "companySize", display: "Company Size", error: false },
        {name: "companyLocale", display: "Company Locale", error: false}
        // { name: "eventsPerYear", display: "Events Per Year", error: false }
      ],
      // countries: [
      //   { label: "Canada", value: "Canada" },
      //   {
      //     label: "United States",
      //     value: "United States"
      //   }
      // ],
      countries: [
        "Afghanistan",
        "Albania",
        "Algeria",
        "Andorra",
        "Angola",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bhutan",
        "Bolivia (Plurinational State of)",
        "Bosnia and Herzegovina",
        "Botswana",
        "Brazil",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cabo Verde",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Colombia",
        "Comoros",
        "Congo",
        "Congo, Democratic Republic of the",
        "Costa Rica",
        "Côte d'Ivoire",
        "Croatia",
        "Cuba",
        "Cyprus",
        "Czechia",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Eswatini",
        "Ethiopia",
        "Fiji",
        "Finland",
        "France",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Greece",
        "Grenada",
        "Guatemala",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Honduras",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran (Islamic Republic of)",
        "Iraq",
        "Ireland",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea (Democratic People's Republic of)",
        "Korea, Republic of",
        "Kuwait",
        "Kyrgyzstan",
        "Lao People's Democratic Republic",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Mauritania",
        "Mauritius",
        "Mexico",
        "Micronesia (Federated States of)",
        "Moldova, Republic of",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "North Macedonia",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Poland",
        "Portugal",
        "Qatar",
        "Romania",
        "Russia",
        "Rwanda",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan",
        "Suriname",
        "Sweden",
        "Switzerland",
        "Syrian Arab Republic",
        "Taiwan, Province of China",
        "Tajikistan",
        "Tanzania, United Republic of",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom of Great Britain and Northern Ireland",
        "United States of America",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela (Bolivarian Republic of)",
        "Viet Nam",
        "Yemen",
        "Zambia",
        "Zimbabwe"
      ],
      reservedOptions: [
        {label: 'I will offer reserved seating.', value: 'yes'},
        {label: 'I will not offer reserved seating.', value: 'no'},
      ],
      eventsPerYearOptions: [
        {label: "1", value: "1"},
        {label: "2 - 5", value: "2-5"},
        {label: "6 - 10", value: "6-10"},
        {label: "11 - 20", value: "11-20"},
        {label: "21+", value: "21+"}
      ],
      ageGroupOptions: [
        {label: "17 & under", value: "17-"},
        {label: "18 - 22", value: "18-22"},
        {label: "23 - 28", value: "23-28"},
        {label: "29 - 34", value: "29-34"},
        {label: "35 - 45", value: "35-45"},
        {label: "46 - 55", value: "46-55"},
        {label: "56 - 64", value: "56-64"},
        {label: "65+", value: "65+"}
      ],
      stateAbreviations: [
        {short: "AL", name: "Alabama", country: "US"},
        {short: "AK", name: "Alaska", country: "US"},
        {short: "AZ", name: "Arizona", country: "US"},
        {short: "AR", name: "Arkansas", country: "US"},
        {short: "CA", name: "California", country: "US"},
        {short: "CO", name: "Colorado", country: "US"},
        {short: "CT", name: "Connecticut", country: "US"},
        {
          short: "DC",
          name: "District of Columbia",
          alt: ["Washington DC", "Washington D.C."],
          country: "US"
        },
        {short: "DE", name: "Delaware", country: "US"},
        {short: "FL", name: "Florida", country: "US"},
        {short: "GA", name: "Georgia", country: "US"},
        {short: "HI", name: "Hawaii", country: "US"},
        {short: "ID", name: "Idaho", country: "US"},
        {short: "IL", name: "Illinois", country: "US"},
        {short: "IN", name: "Indiana", country: "US"},
        {short: "IA", name: "Iowa", country: "US"},
        {short: "KS", name: "Kansas", country: "US"},
        {short: "KY", name: "Kentucky", country: "US"},
        {short: "LA", name: "Louisiana", country: "US"},
        {short: "ME", name: "Maine", country: "US"},
        {short: "MD", name: "Maryland", country: "US"},
        {short: "MA", name: "Massachusetts", country: "US"},
        {short: "MI", name: "Michigan", country: "US"},
        {short: "MN", name: "Minnesota", country: "US"},
        {short: "MS", name: "Mississippi", country: "US"},
        {short: "MO", name: "Missouri", country: "US"},
        {short: "MT", name: "Montana", country: "US"},
        {short: "NE", name: "Nebraska", country: "US"},
        {short: "NV", name: "Nevada", country: "US"},
        {short: "NH", name: "New Hampshire", country: "US"},
        {short: "NJ", name: "New Jersey", country: "US"},
        {short: "NM", name: "New Mexico", country: "US"},
        {short: "NY", name: "New York", country: "US"},
        {short: "NC", name: "North Carolina", country: "US"},
        {short: "ND", name: "North Dakota", country: "US"},
        {short: "OH", name: "Ohio", country: "US"},
        {short: "OK", name: "Oklahoma", country: "US"},
        {short: "OR", name: "Oregon", country: "US"},
        {short: "PA", name: "Pennsylvania", country: "US"},
        {short: "RI", name: "Rhode Island", country: "US"},
        {short: "SC", name: "South Carolina", country: "US"},
        {short: "SD", name: "South Dakota", country: "US"},
        {short: "TN", name: "Tennessee", country: "US"},
        {short: "TX", name: "Texas", country: "US"},
        {short: "UT", name: "Utah", country: "US"},
        {short: "VT", name: "Vermont", country: "US"},
        {short: "VA", name: "Virginia", country: "US"},
        {short: "WA", name: "Washington", country: "US"},
        {short: "WV", name: "West Virginia", country: "US"},
        {short: "WI", name: "Wisconsin", country: "US"},
        {short: "WY", name: "Wyoming", country: "US"},
        {short: "AS", name: "American Samoa", country: "US"},
        {short: "GU", name: "Guam", country: "US"},
        {short: "MP", name: "Northern Mariana Islands", country: "US"},
        {short: "PR", name: "Puerto Rico", country: "US"},
        {
          short: "UM",
          name: "United States Minor Outlying Islands",
          country: "US"
        },
        {short: "VI", name: "Virgin Islands", country: "US"},
        {short: "AB", name: "Alberta", country: "CA"},
        {short: "BC", name: "British Columbia", country: "CA"},
        {short: "MB", name: "Manitoba", country: "CA"},
        {short: "NB", name: "New Brunswick", country: "CA"},
        {
          short: "NL",
          name: "Newfoundland and Labrador",
          country: "CA",
          alt: ["Newfoundland", "Labrador"]
        },
        {short: "NS", name: "Nova Scotia", country: "CA"},
        {short: "NU", name: "Nunavut", country: "CA"},
        {short: "NT", name: "Northwest Territories", country: "CA"},
        {short: "ON", name: "Ontario", country: "CA"},
        {short: "PE", name: "Prince Edward Island", country: "CA"},
        {short: "QC", name: "Quebec", country: "CA"},
        {short: "SK", name: "Saskatchewan", country: "CA"},
        {short: "YT", name: "Yukon", country: "CA"}
      ],
      country: "",
      zipCode: "",
      postalLength: 7,
      regionName: "",
      city: "",
      companyName: "",
      companyAddress: "",
      companyEmail: "",
      companyWebsite: "",
      companyPhone: "",
      ageGroup: "",
      companySize: "",
      companyLocale: "",
      eventsPerYear: "",
      oldEvent: {},
      countryBus: new Vue(),
      ageBus: new Vue(),

      infoFound: false,
      isCoordVerified: false,
      dateObjToParse: {}
    };
  },
  watch: {
    "generalInfo.country"(val) {
      if (val == "Canada") {
        this.postalLength = 7;
      } else {
        this.postalLength = 5;
      }
      if (this.generalInfo.zipCode) {
        this.generalInfo.zipCode = postalFormat(this.zipCode, val);
      }
    },
    info(val) {
      for (let key in val) {
        this.generalInfo[key] = val[key];
        this[key] = val[key];
        if (key == 'useReservedSeats') {
          this.generalInfo[key] = val[key] ? 'yes' : 'no';
          this.generalInfo['oldReservedSeats'] = val[key] ? 'yes' : 'no';
        }
      }
      // if (val) {
      //   this.infoFound = true;
      // }
      // if (val.ageGroup) {
      //   val.ageGroup.split(", ").forEach(g => {
      //     const option = this.ageGroupOptions.find(a => a.value === g);
      //     if (option) {
      //       this.$set(option, "selected", true);
      //     }
      //   });
      // }
      if (val.eventsPerYear) {
        let labelToDisplay = this.eventsPerYearOptions.find(
          ele => ele.value == val.eventsPerYear
        );
        let objToSend = {
          placeholder: labelToDisplay.label,
          value: val.eventsPerYear
        };
      }
      if (val.country) {
        let labelToDisplay = this.countries.find(
          ele => ele.value == val.country
        );
        let objToSend = {
          placeholder: labelToDisplay.label,
          value: val.country
        };
        this.countryBus.$emit("set-value-placeholder", objToSend);
      }
      this.$forceUpdate();
      this.$bus.$emit("check-phone-field", this.generalInfo.companyPhone);
    },
    pageInfo(val) {
      for (let key in val) {
        this.pageInfo[key] = val[key];
        this[key] = val[key];
      }
    },
    coordVerified(val) {
      console.log("is the coord being verified?");
      this.isCoordVerified = val;
    },
    dateObjToParse(val) {
      let addressToParse = this.generalInfo.companyAddress.split(", ");
      let countryOfChoice;
      let postalCode = val["postal_code"];
      let regionAbreviation;
      if (val["administrative_area_level_1"]) {
        regionAbreviation = val["administrative_area_level_1"];
      } else {
        regionAbreviation = addressToParse[2];
      }
      this.stateAbreviations.forEach(element => {
        if (regionAbreviation == element.short) {
          this.regionName = element.name;
          countryOfChoice = element.country;
          return;
        }
      });
      let objToSend = {
        value: "",
        placeholder: ""
      };
      if (countryOfChoice == "US") {
        countryOfChoice = "United States";
        objToSend.value = "United States";
        objToSend.placeholder = "United States";
        this.countryBus.$emit("set-value-placeholder", objToSend);
      } else if (countryOfChoice == "CA") {
        countryOfChoice = "Canada";
        objToSend.value = "Canada";
        objToSend.placeholder = "Canada";
        this.countryBus.$emit("set-value-placeholder", objToSend);
      }
      if (postalCode) {
        this.zipCode = postalFormat(postalCode, countryOfChoice);
      }
      let city = val["region"];
      if (city) {
        this.city = city;
      } else {
        this.city = addressToParse[1];
      }
      this.generalInfo.companyAddress = addressToParse[0];
      this.displayAddress();
    }
  },
  computed: {
    emailValidation: formulateEmailValidation,
    emailValidationMessage: formulateEmailValidationMessage,
    companyWebsiteValidation() {
      return websiteCheck(this.generalInfo.companyWebsite)
        ? "required"
        : "url|required";
    },
    // phoneOutput: {
    //   get() {
    //     return this.companyPhone;
    //   },
    //   set(value) {
    //     this.companyPhone = formatPhoneNumber(value);
    //   }
    // },
    userEmail() {
      return this.$store.state.user.email;
    },
    ageGroupPlaceholder() {
      return this.ageGroupOptions
        .filter(g => g.selected)
        .map(g => g.label)
        .join(", ");
    }
  },
  methods: {
    companyWebsiteBlur() {
      if (
        websiteCheck(this.generalInfo.companyWebsite) &&
        !HTTPS_REGEX.test(this.generalInfo.companyWebsite) &&
        this.generalInfo.companyWebsite !== ""
      ) {
        this.generalInfo.companyWebsite =
          "https://" + this.generalInfo.companyWebsite;
      }
    },
    sendToBrandPage(subdomain) {
      if (subdomain) {
        switch (process.env.VUE_APP_ENVIRONMENT) {
          case "development":
            window.open(
              `http://localhost:3200/brand-page/${subdomain}`,
              "_blank"
            );
            break;
          case "staging":
            window.open(
              `https://planners.staging.incredevent.com/brand-page/${subdomain}`,
              "_blank"
            );
            break;
          default:
            window.open(
              `https://planners.incredevent.com/brand-page/${subdomain}`,
              "_blank"
            );
            break;
        }
      }
    },
    closeModal() {
      this.processing = false;
    },
    error(input) {
      const error = this.errors.find(e => e.name === input);
      return error && error.error;
    },
    validate() {
      console.log("validating");
      this.errors.forEach(ele => {
        ele.errors = [];
        if (ele.name === "ageGroup" || ele.name === "eventsPerYear") {
          if (!this[ele.name] || this[ele.name] <= 0) {
            ele.error = true;
          } else {
            ele.error = false;
          }
        } else if (ele.name === "companyPhone") {
          if (this.companyPhone === "") {
            ele.error = false;
          } else if (!this[ele.name]) {
            ele.error = true;
          } else {
            ele.error = false;
          }
        } else if (ele.name === "companyEmail") {
          if (this[ele.name].includes("@")) {
            ele.error = false;
          } else {
            ele.error = true;
            ele.errors.push(`${ele.display} requires a proper email`);
          }
        } else {
          if (!this[ele.name]) {
            ele.error = true;
          } else {
            ele.error = false;
          }
        }
        if (ele.error && ele.errors.length === 0) {
          ele.errors.push(`${ele.display} is Required`);
        }
      });
      return !this.errors.some(e => e.error);
    },
    saveBrand(values) {
      this.processing = true;

      this.$axios
        .post("/company/edit-info", {
          brandInfo: {
            companyName: values.companyName,
            companyWebsite: values.companyWebsite,
            companyPhone: values.companyPhone,
            companyEmail: values.companyEmail,
            companyAddress: values.companyAddress,
            cityHQ: values.cityHQ,
            city: values.city,
            regionName: values.regionName,
            country: values.country,
            zipCode: values.zipCode,
            companySize: values.companySize,
            eventsPerYear: values.eventsPerYear,
            ageGroup: values.ageGroup,
            estTicketSales: values.estTicketSales,
            useReservedSeats: values.useReservedSeats == 'yes' ? true : false,
          },
          url: this.subdomain
        })
        .then(({data}) => {
          if (!data.error) {
            if (!this.$store.state.user.coordinator) {
              this.$store.state.user.coordinator = true;
              updateSession(this);
            }
            if (this.generalInfo['oldReservedSeats'] !== values.useReservedSeats && values.useReservedSeats == 'yes') {
              setTimeout(() => {
                this.processing = false;
                this.$refs.alert.open({
                  title: "Reserve Seats",
                  msg: "Our team must setup a reserved seating design suite for you. This may take up to 72 hours but is usually completed sooner. We will send you an email when your suite has been setup. You can create events without reserved seating without any wait time.",
                  onConfirm: () => {
                    this.$store.state.bus.$emit("alert", ModalContent.brandUpdated);
                    this.sendToBrandPage(data.redirect);
                  },
                });
              }, 2000);
            } else {
              setTimeout(() => {
                this.processing = false;
                this.$store.state.bus.$emit("alert", ModalContent.brandUpdated);
                this.sendToBrandPage(data.redirect);
              }, 2000);
            }
          } else {
            this.processing = false;

            this.$store.state.bus.$emit("alert", {
              title: "Error",
              msg: data.msg
            });
          }
        })
        .catch(error => console.log(error));
    },

    saveData() {
      if (this.validate()) {
        let objToSend = {
          companyObj: {}
        };
        this.errors.forEach(ele => {
          objToSend.companyObj[ele.name] = this[ele.name];
        });
        objToSend.companyObj["companyAddress"] = this.companyAddress;
        objToSend.type = "generalInfo";
        objToSend.userEmail = this.userEmail;
        let objToValidate = ["companyInfo", objToSend];

        this.$emit("send-data", ...objToValidate);
      } else {
        let objToSend = ["generalInfo", true];
        this.$emit("send-to-errors", ...objToSend);
      }
    },
    displayAddress() {
      setTimeout(() => {
        let addressEle = document.querySelector("#company-address");
        addressEle.value = `${this.generalInfo.companyAddress}`;
      }, 50);
    },
    autoCompleteAddress(val) {
      this.dateObjToParse = val;
      if (val.country === UNITED_STATES || val.country === CANADA) {
        this.generalInfo.country = val.country;
        this.generalInfo.regionName = this.stateAbreviations.find(
          state => state.short === val.administrative_area_level_1
        ).name;
      }
      this.generalInfo.city = val.locality;
    },
    userInput(val) {
      this.generalInfo.companyAddress = val;
    },
    onSelectAgeGroup(value, selected) {
      this.$set(
        this.ageGroupOptions.find(p => p.value === value),
        "selected",
        selected
      );

      this.generalInfo.ageGroup = this.ageGroupOptions
        .filter(g => g.selected)
        .map(g => g.label)
        .join(", ");
    }
  },
  async mounted() {
    let self = this;

    let postalCodeEle = document.getElementById("postal-code");

    if (postalCodeEle)
      postalCodeEle.addEventListener("keyup", function (e) {
        if (e.keyCode == 8 || e.keyCode == 48) {
        } else {
          self.zipCode = postalFormat(self.zipCode, self.country);
        }
      });
    if (this.companyBus) {
      this.companyBus.$on("save-info", args => {
        if (this.validate()) {
          this.saveData();
        }
      });
      // this.companyBus.$on("save-company-info", (args) => {
      //   if (this.validate()) {
      //     let objToSend = ["generalInfo", false];
      //     this.saveData();
      //     this.$emit("send-to-errors", ...objToSend);
      //   } else {
      //     let objToSend = ["generalInfo", true];
      //     this.$emit("send-to-errors", ...objToSend);
      //     // window.scrollTo(0, 10)
      //   }
      // });
    }
  }
};
</script>

<style lang="less">
.information-wrapper {
  width: 100%;

  .general-info-wrapper {
    max-width: 100%;
    margin-bottom: 1.5em;

    .general-info {
      width: 100%;

      .general-info-title {
        // max-width: 45%;
        .general-title {
          text-align: center;
          font-size: 24pt;
          color: var(--headers);
        }

        //end of general-title-text
      }

      .page-info-title {
        h4 {
          color: var(--text);
        }
      }

      .card-container {
        margin-bottom: 32px;
      }

      //end of general-info-title
      .general-input-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(264px, 1fr));
        grid-column-gap: 15px;
      }

      //end of general-input-wrapper
    }

    //end of general-info
  }

  //end of general-info-wrapper

  .secondary-info-wrapper {
    max-height: 30%;
    max-width: 100%;

    .secondary-info {
      width: 100%;

      .secondary-info-title {
        .secondary-title {
          text-align: center;
          font-size: 24pt;
          color: white;
        }
      }

      //end of secondary-info-title
      .secondary-input-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(264px, 1fr));
        grid-column-gap: 15px;

        .image-wrapper {
          width: 65%;
          height: 116px;

          .venue-img-wrapper {
            height: unset;

            .venue-file-upload-container {
              height: 178px;

              label {
                width: 100%;
                margin-top: 28px;
                height: unset;
              }
            }
          }
        }
      }
    }

    //end of secondary-info
  }

  //end of secondary-info-wrapper
  .title-text {
    color: var(--headers);
  }

  //end of title-text
  .info-input-wrapper {
    display: block;
    position: relative;
    // width: calc(350px - 1em);
  }

  //end of info-input-wrapper
  .btn-wrapper {
    display: flex;
    justify-content: flex-end;

    .general-save-button {
      margin: 0;
      padding: 0.75em 2em;
      width: 200px;
      background-color: #4cac55;
      background: linear-gradient(#4cac55, #39793f);
      color: white;
      border-color: #5fb469;
      padding: 8px 18px;
      position: relative;
      border: 1px solid #5fb469;
      border-radius: 5px;
      outline: none;
      font-size: 1em;

      font-weight: 500;
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: linear-gradient(#5bc465, #448b4b);
        opacity: 0;
        transition: 0.4s;
        border-radius: 5px;
      }

      &:hover::before {
        opacity: 1;
      }
    }

    //end general-save-button
  }

  //end btn-wrapper
}

//end of infomation-wrapper
</style>
