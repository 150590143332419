<template>
  <div class="seller-finance-wrapper">
    <div class="seller-finance-title">
      <h2 class="page-title">Planner's Financial Information</h2>
    </div>
    <div class="seller-finance">
      <h4>Payout Information</h4>
      <p class="general-description b1">
        We require your banking information for payouts, unless you want to give
        us all of your revenue ... we're completely okay with that. 😂 Depending
        on the event and the length of time in which you've been a customer of
        INCREDEVENT, payouts may take 3 - 30 business days. Often times (95%)
        though, events are paid out within 5 business days. If you'd like to be
        considered for payouts as an event is happening, please email us at
        <a
          class="mail-link"
          href="mailto:411@incredevent.com?subject=Early Event Payouts"
        >
          411@incredevent.com
        </a>
        and we'll vet your account.
      </p>
      <p class="general-description b1">
        We process payments on your behalf. Depending on the nature of the
        payment it can take 1 - 7 business days for that revenue to enter our
        system.
      </p>
      <p class="general-description b1">
        eTransfer & direct deposit fees are charged to the Planner.
      </p>
      <h4 class="credit-title body-1">Understanding Fraud</h4>
      <p class="credit-disclaimer body-1 b1">
        Please note that buyers have up to 90-days after their event from the
        time that their credit card statement is issued to report a fraudulent
        claim. We will make every effort to solve the fraud claim in your
        favour, however, banks that issue credit cards will debit the funds from
        your account before the dispute is addressed, and will only return the
        funds if the judgment is in your favour. Our system has the strictest
        industry protocols, confirming credit card information, addresses, name,
        and IP location. INCREDEVENT also manually confirms any purchase over
        $500, and buyers of multiple tickets through more than one transaction
        over $500 are contacted. Despite this, no fraud protection system will
        catch 100% of possible fraud, and sellers should hold back 5% of funds
        for 90-days to offset potential claims.
      </p>
    </div>
  </div>
</template>

<style lang="less" scoped>
.seller-finance-wrapper {
  .seller-finance-title {
    h2 {
      justify-content: center;
    }
  }
  .seller-finance {
    .mail-link {
      color: var(--headers);
    }
  }
  //end seller-finance-title
}
//end seller finance wrapper
</style>

<script>
export default {};
</script>
