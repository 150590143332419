<template>
  <transition name="fade" appear>
    <div class="slider-wrapper">
      <div class="slider-container">
        <div class="slider-content">
          <div class="slider-info" :sliderMsg="sliderMsg">{{ sliderMsg }}</div>
          <div class="slider-exit">
            <button v-on:click="$emit('dismiss')">&times;</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="less" scoped>
.slider-wrapper {
  width: 100%;
  position: fixed;
  z-index: 100;
  .slider-container {
    display: flex;
    justify-content: center;
    margin: auto;
    background-color: #4a639e;

    .slider-content {
      min-width: 1100px;
      display: flex;
      justify-content: space-between;
      position: relative;

      @media screen and (max-width: 1175px) {
        width: 94%;
        min-width: unset;
      }

      .slider-info {
        padding: 23px 0px;
        color: white;
      }
      .slider-exit {
        position: absolute;
        right: 16px;
        top: 16px;
        // height: min-content;
        button {
          display: flex;
          align-items: center;
          color: #fff;
          background-color: #4a639e;
          border: none;
          font-size: 2em;
          width: 35px;
          height: 35px;
          //    height: min-content;
          cursor: pointer;
          text-align: center;
          padding: 0px 0px 0px 0px;
        }
        // button:hover {
        //   background-color: #e60022;
        //   border-radius: 100%;
        // }
      }
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.1s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}
</style>

<script>
export default {
  name: "pop-up-slider",
  props: {
    sliderMsg: String,
  },
};
</script>
