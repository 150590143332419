<template>
  <div class="input-wrapper" :class="{ error }">
    <label :style="labelStyle" v-if="type != `checkbox`">
      {{ label }}
      <b v-if="required && !noStars">*</b>
      <!-- <div class="tooltip">
        <div class="tooltip-text">
          <span>{{
            tooltip ||
              "Use your keyboard to input characters that will be used against you in a court of law."
          }}</span>
        </div>
        <img v-if="label" src="/img/smallIcon.png" />
      </div> -->
    </label>
    <div class="input-mask" v-if="readOnly"></div>
    <!-- this stupid bullshit is to work around chromes stupid bullshit -->
    <input style="display: none" type="text" name="fakeusernameremembered" />
    <input style="display: none" type="text" name="fake-address" />

    <vue-google-autocomplete
      ref="autocomplete"
      v-if="type == 'location'"
      :id="id"
      :placeholder="placeholder"
      :value="val"
      :types="mapType"
      class="auto-search"
      :country="['ca', 'us']"
      v-on:change="$emit('user-input', $event)"
      v-on:placechanged="$emit('updated', $event)"
      :disabled="readOnly"
    />

    <vue-google-autocomplete
      ref="autocomplete"
      v-if="type == 'locationWithCountry'"
      :id="id"
      :placeholder="placeholder"
      :value="val"
      :types="mapType"
      class="auto-search"
      :country="country"
      v-on:change="$emit('input', $event)"
      v-on:placechanged="$emit('updated', $event)"
      v-on:blur="$emit('blur', $event)"
      :disabled="readOnly"
    />

    <div class="error-msg" v-if="error">
      <div class="error-msg-inner">
        <font-awesome-icon class="icon" icon="exclamation-triangle" />
        {{ error }}
      </div>
    </div>
    <small class="sublabel" v-else-if="sublabel">{{ sublabel }}</small>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueGoogleAutocomplete from "vue-google-autocomplete";

library.add(faExclamationTriangle);

export default {
  name: "google-autocomplete-input",
  components: {
    VueGoogleAutocomplete,
    FontAwesomeIcon
  },
  props: {
    id: String,
    placeholder: String,
    val: String | Number,
    country: Array,
    mapType: String,
    readOnly: Boolean,
    label: String,
    labelStyle: Object,
    type: String,
    required: Boolean,
    noStars: Boolean,
    tooltip: String,
    error: Boolean | String,
    sublabel: String
  }
};
</script>

<style lang="less" scoped>
.input-wrapper {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  margin-bottom: 1.5em;
  position: relative;
  width: 100%;

  .input-mask {
    position: absolute;
    left: 0px;
    top: 29px;
    width: 100%;
    height: 52px;
    border-radius: 5px;
    background-color: rgba(82, 82, 82, 0.5);
  }

  label {
    display: block;
    flex-basis: 100%;
    margin-bottom: 0.5em;
    font-size: 16px;
    font-weight: 200;

    b {
      padding-left: 2px;
      color: @colors[input-required];
    }

    .tooltip {
      position: none;
      top: 0;
      right: 5px;
      transition: 0.4s;

      img {
        width: 20px;
        height: 20px;
      }

      .tooltip-text {
        position: absolute;
        width: 300px;
        opacity: 0;
        padding: 2px;
        bottom: 30px;
        right: 0;
        color: @colors[text];
        transition: 0.4s;
        background-image: @colors[primary-gradient];
        border-radius: 5px;
        pointer-events: none;

        span {
          display: block;
          padding: 4px 6px;
          background: @colors[tooltip-bg];
          border-radius: 5px;
        }
      }

      &:hover {
        .tooltip-text {
          opacity: 1;
          pointer-events: all;
        }
      }
    }
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="number"],
  input[type="time"],
  input[type="text-time"],
  input[type="text-time-tier"],
  input[type="money"],
  input[type="date"],
  textarea {
    display: block;
    padding: 12px 0px;
    text-indent: 14px;
    width: 100%;
    font-family: inherit;
    font-size: 1em;
    color: var(--text);
    background: var(--input-background);
    border: 1px solid var(--input-border);
    border-radius: 8px;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    transition: all 0.4s ease, visibility 0s;
    &:hover {
      border: 1px solid #4d4d4d;
    }
    &:focus {
      color: var(--text);
      border: 1px solid #48bb8b;
    }
  }

  .sublabel {
    font-size: 12px;
    padding: 5px;
  }
  .error-msg {
    position: relative;
    width: 100%;
    top: -1px;
    margin: 0 6px;
    border: 1px solid #1a0306;
    border-top: 1px solid transparent;
    border-radius: 0 0 5px 5px;
    background: #4e2e33;
    transition: 0.4s;

    .error-msg-inner {
      color: white;
      padding: 2px 5px 4px 5px;

      .icon {
        padding-right: 5px;
      }
    }
  }
}
</style>
