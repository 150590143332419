<template>
  <div class="company-design-wrapper">
    <!-- <error-list :errors="errors" /> -->
    <div class="company-title-wrapper">
      <div class="company-title-inner">
        <div class="page-title-lol">
          <h2>Page Design Options</h2>
        </div>
      </div>
    </div>
    <div class="company-page-wrapper">
      <div class="company-page-inner">
        <div class="company-first-row">
          <div class="company-settings-wrapper subdomain">
            <div class="company-settings-label"></div>
            <diyobo-input
              type="text"
              placeholder="subdomain"
              required
              v-model="subdomain"
              :val="subdomain"
              label="Create Your Subdomain"
              :error="error('subdomain')"
            />
          </div>
          <div class="company-settings-wrapper colours">
            <label class="page-design-labels b1 button-label"
              >Page Colours</label
            >
            <div class="colour-picker-wrapper">
              <button
                v-for="(button, index) in displayButtons"
                :key="index"
                class="color-btn"
                :id="'btn-' + button.name"
              >
                <div
                  :class="{ color_text: button.name == 'text' }"
                  class="color-container"
                ></div>
                {{ button.display }}
              </button>
            </div>
          </div>
        </div>
        <div class="company-second-row">
          <div class="company-settings-wrapper cover">
            <div class="website-wrapper">
              <image-uploader
                type="venue"
                required
                :bus="bus"
                :componentCount="0"
                :info="websiteImage"
                :oldEvent="oldEvent"
                :key="50"
                ref="websiteImg"
                v-on:data="uploadImages('websiteImage', ...arguments)"
                v-on:remove-image="removeImg('websiteImage')"
                popUpContent="Image Dimensions should be between 1600px x 600px and 1100px x 300px."
                :error="error('websiteImage')"
              />
            </div>
          </div>
          <div class="company-settings-wrapper profile">
            <div class="profile-wrapper">
              <logo-uploader
                type="hero"
                label="Profile Photo"
                required
                :bus="bus"
                :txt="'profile'"
                :info="profileImage"
                ref="profileImage"
                :indexToInject="1"
                v-on:data="uploadImages('profileImage', ...arguments)"
                v-on:remove-image="removeImg('profileImage')"
                :error="error('profileImage')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
.company-design-wrapper {
  .company-page-wrapper {
    .colours {
      width: 66%;
      .colour-picker-wrapper {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-evenly;
        margin-top: 9px;
        button {
          border: none;
          border-radius: 5px;
          margin: 0 5px 10px;
        }
      }
    }
    .buttons {
      display: flex;
      justify-content: center;
      .button-wrapper {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        .btn {
          width: 200px;
          background-color: #4cac55;
          background: linear-gradient(#4cac55, #39793f);
          color: #fff;
          border-color: #5fb469;
          padding: 8px 18px;
          position: relative;
          border: 1px solid #5fb469;
          border-radius: 5px;
          outline: none;
          font-size: 1em;

          font-weight: 500;
          cursor: pointer;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            background: linear-gradient(#5bc465, #448b4b);
            opacity: 0;
            transition: 0.4s;
          }

          &:hover::before {
            opacity: 1;
          }
        }
        //end btn
        .page-btn {
          padding: 0.75em 0em;
          text-align: center;
        }
      }
      //end button wrapper
    }
    //end buttons
    .color-btn {
      border-radius: 8px;
      // background-color: #4cac55;
      background: #46a755;
      color: black;
      border-color: #5fb469;
      padding: 8px 8px;
      position: relative;
      border: 1px solid #5fb469;
      border-radius: 5px;
      outline: none;
      font-size: 1em;
      font-weight: 500;
      cursor: pointer;

      @media screen and (max-width: 468px) {
        margin-right: 30px !important;
        width: 200px;
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: linear-gradient(#5bc465, #448b4b);
        opacity: 0;
        transition: 0.4s;
        border-radius: 5px;
      }

      &:hover::before {
        opacity: 1;
      }
      display: flex;
      align-items: center;
      //cursor: pointer;
      .color-container {
        width: 30px;
        height: 30px;
        margin-right: 5px;
        background-color: black;
        box-shadow: 0px 0px 10px 0px #41954ded;
      }
      .color_text {
        background-color: white;
      }
    }

    .subdomain {
      width: 32%;
      margin-right: 12px;
    }
    //end subdomain
    .profile {
      width: 30%;
      .profile-label {
        margin-top: 1.8em !important;
        margin-bottom: 1em !important;
      }
      .profile-label.error {
        color: #e60022;
      }
    }
    //end profile
    .cover {
      width: 70%;
    }
    .company-first-row {
      display: flex;
      flex-flow: row wrap;
    }
    //end company-first-row
    .company-second-row {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }
    //end company-second-row
    .company-settings-wrapper {
      .company-settings-label {
        margin: 1em 0;
        color: #4eb95e;
        text-transform: uppercase;
        display: inline-block;
        max-width: 100%;
        font-weight: 700;
        margin-right: 5px;
      }
      .page-design-labels {
        margin-top: 2.2em;
        /* margin-top: 10%; */
        display: block;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        margin-bottom: 0.5em;
        // font-family: roboto, sans-serif;
        font-size: 16px;
        font-weight: 200;
      }
      .page-design-labels.button-label {
        padding-left: 8%;
      }
      .less-margin {
        margin-top: 2.2em;
        margin-bottom: 0.5em;
      }
      .inline-label {
        display: inline-block;
        margin-right: 5px;
      }
      //end company-settings-label

      .website-wrapper {
        width: 98%;
      }
      .profile-wrapper {
        //  width: 399px;
        // width: 81%;
      }
    }

    //end company-settings-wrapper
  }
  //end company-page-wrapper
}
@media only screen and (max-width: 782px) {
  .company-design-wrapper {
    .company-page-wrapper {
      .company-first-row {
        .subdomain {
          width: 100%;
        }
        .colours {
          width: 100%;
          .colour-picker-wrapper {
            justify-content: initial;
          }
        }
      }
      .company-second-row {
        flex-flow: row wrap-reverse;
        .cover,
        .profile {
          width: 100%;
        }
      }
      .company-settings-wrapper {
        .website-wrapper {
          width: 100%;
        }
      }
    }
  }
}
//end company-design-wrapper
</style>

<script>
import Vue from "vue";
import ImageUploader from "../../components/ImageUploader.vue";
import LogoUploader from "../../components/imageUploaders/TTButtonImageUploader.vue";
import DiyoboInput from "@/components/DiyoboInput.vue";
import Picker from "vanilla-picker";
import ErrorList from "../../components/ErrorList.vue";
import PopUpsForIcons from "../../components/PopUpsForIcons.vue";

export default {
  components: {
    ImageUploader,
    DiyoboInput,
    Picker,
    LogoUploader,
    ErrorList,
    PopUpsForIcons
  },
  props: {
    companyBus: Object
  },
  data() {
    return {
      displayButtons: [
        { name: "header", display: "Header", value: "backgroundColorObj" },
        { name: "text", display: "Header Text", value: "headerColorObj" },
        {
          name: "contact-text",
          display: "Contact Text",
          value: "contactTextColorObj"
        },
        {
          name: "page",
          display: "Page Background",
          value: "headerTextColorObj"
        }
      ],
      errors: [
        { name: "subdomain", display: "Subdomain", error: false },
        { name: "websiteImage", display: "Company Cover Image", error: false },
        {
          name: "profileImage",
          display: "Company Profile Image",
          error: false
        }
      ],
      objToSend: {},
      subdomain: "",
      profileImage: {},
      websiteImage: {
        images: {
          hero: ""
        },
        imageDetails: {
          x: "",
          y: "",
          croppedImg: "",
          zoomLevel: ""
        }
      },
      bus: new Vue(),
      oldEvent: {},
      backgroundColor: "",
      headerColor: "",
      headerTextColor: "",
      contactTextColor: "",
      backgroundColorObj: {},
      headerColorObj: {},
      headerTextColorObj: {},
      contactTextColorObj: {},
      color1: "#222222",
      color2: "#ffffff",
      color3: "#222222",
      color4: "#4eb95e",
      shutTheFuckUp: false
    };
  },
  computed: {
    userEmail() {
      return this.$store.state.user.email;
    }
  },
  methods: {
    applyPickerToButtons() {
      let headerBtn = document.querySelector("#btn-header");
      let textBtn = document.querySelector("#btn-text");
      let pageBtn = document.querySelector("#btn-page");
      let contactBtn = document.querySelector("#btn-contact-text");

      console.log(window.innerWidth);
      let width = window.innerWidth;
      let pos2 = "bottom";
      let pos3 = "bottom";
      let pos4 = "bottom";

      if (width < 1250 && width >= 989) {
        pos3 = "left";
      } else if (width < 1250 && width >= 783) {
        pos4 = "left";
      } else if (width <= 740 && width > 652) {
        pos3 = "left";
      } else if (width <= 590 && width > 469) {
        pos4 = "left";
      }

      let picker1 = new Picker({
        parent: headerBtn,
        color: this.color1,
        popup: "bottom"
      });
      let picker2 = new Picker({
        parent: textBtn,
        color: this.color2,
        popup: pos2
      });
      let picker3 = new Picker({
        parent: pageBtn,
        color: this.color3,
        popup: pos3
      });
      let picker4 = new Picker({
        parent: contactBtn,
        color: this.color4,
        popup: pos4
      });

      console.log(picker3);

      let self = this;

      picker1.onChange = function (color) {
        headerBtn.firstElementChild.style.backgroundColor = color.rgbaString;
        self.headerColor = color.rgbaString;
        self.headerColorObj = { backgroundColor: color.rgbaString };
      };
      picker2.onChange = function (color) {
        textBtn.firstElementChild.style.backgroundColor = color.rgbaString;
        self.headerTextColor = color.rgbaString;
        self.headerTextColorObj = { backgroundColor: color.rgbaString };
      };
      picker3.onChange = function (color) {
        pageBtn.firstElementChild.style.backgroundColor = color.rgbaString;
        self.backgroundColor = color.rgbaString;
        self.backgroundColorObj = { backgroundColor: color.rgbaString };
      };
      picker4.onChange = function (color) {
        contactBtn.firstElementChild.style.backgroundColor = color.rgbaString;
        self.contactTextColor = color.rgbaString;
        self.contactTextColorObj = { backgroundColor: color.rgbaString };
      };

      headerBtn.firstElementChild.style.backgroundColor = "#222222";
      self.headerColorObj = { backgroundColor: "#222222" };
      textBtn.firstElementChild.style.backgroundColor = "#4eb95e";
      self.headerTextColorObj = { backgroundColor: "#4eb95e" };
      pageBtn.firstElementChild.style.backgroundColor = "#222222";
      self.backgroundColorObj = { backgroundColor: "#222222" };
      contactBtn.firstElementChild.style.backgroundColor = "#ffffff";
      self.contactTextColorObj = { backgroundColor: "#ffffff" };
    },
    saveData() {
      this.bus.$emit("image-upload-data");
    },
    savePageInfo(id) {
      let objToSend = this.objToSend;
      this.saveData();
      const data = new FormData();
      data.append("companyObj", JSON.stringify(objToSend.companyObj));
      data.append("type", "pageInfo");

      data.append("hero", this.websiteImage.images.heroFile);
      data.append("profile", this.profileImage.imageFile);
      data.append("id", id);

      this.$axios.post("/company/save-page-info", data).then((response) => {
        let data = response.data;
        if (response.status == 200) {
          console.log(data);
        }
      });
    },
    uploadImages(key, value) {
      this[key] = value;
    },
    validate() {
      this.errors.forEach((ele) => {
        ele.errors = [];
        if (ele.name == "websiteImage") {
          if (this[ele.name].images.hero.length > 0) {
            ele.error = false;
          } else {
            ele.error = true;
          }
        } else {
          if (!this[ele.name]) {
            ele.error = true;
          } else {
            ele.error = false;
          }
        }
        if (ele.error) {
          ele.errors.push(`${ele.display} is Required`);
        }
      });
      return !this.errors.some((e) => e.error);
    },
    finalizeData(link) {
      const objToSend = new Object();
      this.saveData();
      if (this.validate()) {
        let objToSend = {
          companyObj: {
            websiteImage: {
              hero: ""
            },
            profileImage: "",
            subdomain: ""
          }
        };
        // Object.defineProperties(objToSend,{
        //   companyObj:{
        //     websiteImage:this.websiteImage.images.heroFile
        //   }
        // })
        objToSend.companyObj = {};

        objToSend.companyObj.subdomain = this.subdomain;
        objToSend.userEmail = this.userEmail;
        objToSend.companyObj.backgroundColor =
          this.backgroundColor || "#222222";
        objToSend.companyObj.headerColor = this.headerColor || "#222222";
        objToSend.companyObj.headerTextColor =
          this.headerTextColor || "#ffffff";
        objToSend.companyObj.contactTextColor =
          this.contactTextColor || "#4eb95e";
        objToSend.type = "pageInfo";

        // Object.add()
        this.objToSend = objToSend;
        let objToValidate = ["pageInfo", objToSend];
        console.log(objToValidate);
        this.$emit("send-data", ...objToValidate);
      } else {
        let objToSend = ["pageInfo", true];
        this.$emit("send-to-errors", ...objToSend);
      }
    },
    sendToCompanyPage() {
      let self = this;
      this.finalizeData(true);
    },
    removeImg(key) {
      if (key == "websiteImage") {
        this.websiteImage = {};
      } else {
        this.profileImage = "";
      }
    },
    error(input) {
      let error = this.errors.find((e) => e.name === input);
      return error && error.error;
    }
  },
  mounted() {
    this.applyPickerToButtons();
    if (this.companyBus) {
      this.companyBus.$on("save-company-info", (args) => {
        if (this.shutTheFuckUp) return;
        this.saveData();
        if (this.validate()) {
          let objToSend = ["pageInfo", false];
          this.finalizeData();
          this.$emit("send-to-errors", ...objToSend);
          this.shutTheFuckUp = true;
        } else {
          let objToSend = ["pageInfo", true];
          this.$emit("send-to-errors", ...objToSend);
          // window.scrollTo(0,3500)
        }
      });
    }
  }
};
</script>
